import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const partnersCarousels = document.querySelectorAll(
    '.partners-carousel-block-01 .partners-carousel__carousel'
  );

  if (0 < partnersCarousels.length) {
    partnersCarousels.forEach((carousel) => {
      const args = {
        slidesPerView: 'auto',
        centerInsufficientSlides: true,
        spaceBetween: 30,
        loop: true,

        breakpoints: {
          768: {
            spaceBetween: 40,
          },
          1080: {
            spaceBetween: 80,
          },
        },

        a11y: {
          firstSlideMessage: _x(
            'This is the first slide',
            'carousel first slide label',
            'vtx'
          ),
          lastSlideMessage: _x(
            'This is the last slide',
            'carousel first slide label',
            'vtx'
          ),
          paginationBulletMessage: _x(
            'Go to slide {{index}}',
            'carousel navigation label',
            'vtx'
          ),
          prevSlideMessage: _x(
            'Previous slide',
            'carousel previous label',
            'vtx'
          ),
          nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
        },
      };

      if (!document.documentElement.classList.contains('no-animation')) {
        args.autoplay = {
          delay: 2000,
          disableOnInteraction: false,
        };
      }
      const partnerSwiper = new Swiper(carousel, args);

      document.addEventListener('disable-animations', function () {
        partnerSwiper.autoplay.stop();
      });

      document.addEventListener('enable-animations', function () {
        partnerSwiper.autoplay.start();
      });
    });
  }
});
